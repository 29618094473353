.rating {
    display: inline-block;
    unicode-bidi: bidi-override;
    color: #888888;
    font-size: 48px;
    height: 48px;
    width: auto;
    margin: 0;
    position: relative;
    padding: 0;
  }
  .rating:hover {
    cursor: pointer;
  }
  
  .rating-upper {
    color: #e7711b;
    padding: 0;
    position: absolute;
    z-index: 1;
    display: flex;
    top: 0;
    left: 0;
    overflow: hidden;
    line-height: 48px;
  }
  
  .rating-lower {
    padding: 0;
    display: flex;
    z-index: 0;
    line-height: 48px;
  }
  .rating-upper:hover {
    color: gold;
  }