footer {
    background-color: #272727;
    color: rgba(255, 255, 255, 0.3);
    margin-top: 10rem;
}

.footer-bottom {
    background-color: #1a1a1a;
    min-height: 140px;
    color: rgba(255, 255, 255, 0.3);
    position: relative;
    z-index: 1;
}
.footer-bottom a {
    color: rgba(255, 255, 255, 0.3);
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
}
.footer-menu {
    list-style: none;
    padding: 0;
}
.footer-menu a {
    color: rgba(255, 255, 255, 0.7);
}
.footer-menu a:hover {
    color: #fff;
}
footer form input {
    border-radius: 4px;
}
/*
footer {
    margin-top: 150px;
    width: 100%;
    background-color: #f5f5f5;
}

footer .container {
    flex-direction: column;
}
footer .w-xsm-100 {
    width: 100% !important;
}
*/