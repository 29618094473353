.howto-screenshots {
   /* border: 10px solid lightskyblue;*/
    border-radius: 4px;
}
.howto-items p {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    cursor:pointer;
}
.howto-items p:hover {
    background-color: aliceblue;
}
.aliceblue {
    background-color: aliceblue;
}
.lightskyblue, .lightskyblue:hover {
    background-color: lightskyblue !important;
}
.text-lightskyblue {
    color: lightskyblue;
}
.howto-screenshots img {
    border-radius: 4px;
}