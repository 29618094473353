.calendar-container table {
    border: 1px solid silver;
}
.calendar-container thead {
    background-color: #eee;
    border-bottom: 2px solid #ddd;
}
.calendar-container tr:nth-child(even) {
    background: #eee
}
.calendar-container td {
    padding: 4px 10px;
    min-width: 17%;
}
.calendar-container .fc {
    min-width: 80px;
}
@media (min-width:576px) {
    .calendar-container th {
      padding: 5px 10px;
    }
}