.login-box {
    max-width: 420px;
}
.login-box button {
    background-color: #0d6efd;
    border-color: #0d6efd;
    color: #fff;
}
.login-box button:hover {
    background-color: #0d6efd;
    opacity: 0.9;
    color: #fff;
}