.print-dialog {
    background-color: aliceblue;
    z-index: 1;
    left: 0;
    width: 99%;
}
.print-dialog progress {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 38px;
    border-radius: 50px;
    border: none;
}

.myprogress-bar {
    height: 38px;
    border-radius: 50px;
    border: none;
    background-color: #dfdfdf;
}
.myprogress-bar .progress {
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 50px;
    border: none;
    background-color: rgba(25, 181, 254, 0.7);
}
.progressMsg {
    top: 0;
    left: 0;
}
.print-dialog input {
    border-radius: 4px;
    border: solid 1px silver !important;
    padding: 0.5rem !important;
}
.print-dialog .sendbutton {
    font-family: roboto;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    border-radius: 4px;
    border: none !important;
    margin: 0.5rem 0 !important;
    padding: 0.2rem !important;
}
.print-dialog .sendbutton:hover {
    border: 1px solid;
    box-shadow: inset 0 0 20px hsla(0,0%,100%,.35),0 0 20px hsla(0,0%,100%,.2);
    outline-color: hsla(0,0%,100%,0);
    outline-offset: 15px;
}
.submit-success {
    background-color: azure;
    padding: 1.5rem;
    border-radius: 19px;
    border: 1px solid aquamarine;
}