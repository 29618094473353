@media (min-width: 1600px) {
  body.home {
    background-image: url('../images/bgs/bg1.webp'), url('../images/bgs/bg13.webp'), url('../images/bgs/bg3.webp'), url('../images/bgs/bg33.webp'), url('../images/bgs/bg13.webp');
    background-repeat: no-repeat;
    background-position: 55rem -73rem, -12rem -24rem, 55rem 66rem, -65rem 137rem, 55rem 157rem;
    background-size: contain, 80%, contain, contain, contain;
  }
}
@media (min-width: 1200px) and (max-width: 1599px) {
  body.home {
    background-image: url('../images/bgs/bg1.webp'), url('../images/bgs/bg13.webp'), url('../images/bgs/bg3.webp'), url('../images/bgs/bg33.webp'), url('../images/bgs/bg13.webp');
    background-repeat: no-repeat;
    background-position: 45rem -45rem, -12rem -24rem, 35rem 66rem, -35rem 137rem, 55rem 157rem;
    background-size: contain, 80%, contain, contain, contain;
  }
}
@media (min-width: 950px) and (max-width: 1199px) {
  body.home {
    background-image: url('../images/bgs/bg1.webp'), url('../images/bgs/bg13.webp'), url('../images/bgs/bg3.webp'), url('../images/bgs/bg33.webp'), url('../images/bgs/bg13.webp'), url('../images/bgs/bg33.webp'), url('../images/bgs/bg13.webp');
    background-repeat: no-repeat;
    background-position: 38rem -40rem, -8rem -8rem, 25rem 46rem, -25rem 100rem, 25rem 130rem, -20rem 190rem, 45rem 235rem;
    background-size: contain, 80%, contain, contain, contain;
  }
}
@media (min-width: 768px) and (max-width: 949px) {
  body.home {
    background-image: url('../images/bgs/bg1.webp'), url('../images/bgs/bg13.webp'), url('../images/bgs/bg3.webp'), url('../images/bgs/bg33.webp'), url('../images/bgs/bg13.webp'), url('../images/bgs/bg33.webp'), url('../images/bgs/bg13.webp');
    background-repeat: no-repeat;
    background-position: 25rem -22rem, -8rem -8rem, 25rem 46rem, -25rem 100rem, 25rem 130rem, -20rem 190rem, 35rem 235rem;
    background-size: contain, 80%, contain, contain, contain;
  }
}
@media (min-width: 390px) and (max-width: 768px) {
  body.home {
    background-image: url('../images/bgs/bg1.webp'), url('../images/bgs/bg13.webp'), url('../images/bgs/bg3.webp'), url('../images/bgs/bg33.webp'), url('../images/bgs/bg13.webp');
    background-repeat: no-repeat;
    background-position: 45rem -45rem, -12rem -24rem, 35rem 66rem, -35rem 137rem, 55rem 157rem;
    background-size: contain, 80%, contain, contain, contain;
  }
}
@media (min-width: 992px) {
  .home-thumb {
    max-width: 24rem;
  }
}
@media (min-width: 1280px) {
  .home-thumb {
    max-width: 32rem;
  }
  .navbar-expand-lg {
    margin: 1.25rem 7rem !important;
  }
}
@media (max-width: 1280px) {
  main.home {
    padding: 0 !important;
  }
}
.youtube {
  width: 100%;
  min-height: 210px;
}
@media (min-width: 580px) {
  .youtube {
    width:560px; height:315px;
  }
}
header nav {
  background: rgb(96,165,250);
  /*background: linear-gradient(56deg, rgb(96, 165, 250) 0%, #a5b4fc 100%);*/
  background: linear-gradient(56deg, rgba(191,219,254,1) 24%, rgba(196,181,253,1) 100%); 
}
#logo {
  max-width: 200px;
}
#logo-cal {
  max-width: 48px;
  margin: 2px 0;
}

.page-title {
  text-shadow: -1px 0 4px silver,0 1px 4px silver,1px 0 4px silver,0 -1px 4px silver;
  font-family: 'Nunito';
}
.fw-900 {
  font-weight: 900 !important;
}
.navbar-expand-lg {
  margin: 1.25rem 2rem;
  border-radius: 8px;
  box-shadow: 0 .25rem 1rem rgba(0,0,0,.15);
}
.nav-custom {
  min-height: 38px;
  border-radius: 8px;
  background-image: url('../images/menu-repeat.gif');
  background-repeat: repeat-x;
  background-size: contain;
  margin-top: -24px;
}

.navbar-nav>li>a {
  color: darkslategrey;
  font-weight: 700;
  padding-top: 9px;
  padding-bottom: 15px;
  line-height: 18px;
  padding: 9px 35px 15px 35px;
  position: relative;
  display: block;
}
.navbar-nav > li > a:hover {
  color: white;
  border-bottom: 1px solid #fff;
  padding-bottom: 3px;
}
.shadow {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
}
/** Breadcrumb **/
#breadcrumbs {
  line-height: 17px;
  padding: 0 8px 0 2px;
}
#breadcrumbs .btn {
    transition: none;
}
#breadcrumbs a { 
    font-family: Nunito;
}
.btn-breadcrumb .btn:after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 10px solid white;
  position: absolute;
  top: 50%;
  margin-top: -17px;
  left: 99%;
  z-index: 3;
}
.btn-breadcrumb .btn:before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 10px solid rgb(173, 173, 173);
  position: absolute;
  top: 50%;
  margin-top: -17px;
  margin-left: 1px;
  left: 99%;
  z-index: 3;
}
.btn-breadcrumb .btn.btn-primary:last-child::before, .btn-breadcrumb .btn.btn-primary:last-child::after {
  border-left: 10px solid #19b5fe;
}

/** The Spacing **/
.btn-breadcrumb .btn {
  padding:6px 12px 6px 24px;
}
.btn-breadcrumb .btn:first-child {
  padding:6px 6px 6px 10px;
}
.btn-breadcrumb .btn:last-child {
  padding:6px 18px 6px 24px;
  color: #fff;
}

/** Default button **/
.btn-breadcrumb .btn.btn-default:not(:last-child):after {
  border-left: 10px solid #fff;
}
.btn-breadcrumb .btn.btn-default:not(:last-child):before {
  border-left: 10px solid #ccc;
}
.btn-breadcrumb .btn.btn-default:hover:not(:last-child):after {
  border-left: 10px solid #ebebeb;
}
.btn-breadcrumb .btn.btn-default:hover:not(:last-child):before {
  border-left: 10px solid #adadad;
}

.btn-default {
  border-color: #cccccc !important;
}
.btn-default:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}
/** END Breadcrumb **/

.btn-bf {
  width: 32px;
  height: 32px;
  color: #000;
  background-color: blue;
}
.download-button .bi:before {
vertical-align: -0.15em;
}
.download-button {
font-family: nunito;
}

.list-group-item .bi {
display: none;
}
.list-group-item.active .bi {
display: block;
padding-top: 10px;
}
.related-calendars h2, .weekly-related-calendars h5, .related-calendars-title  {
z-index: 2;
color: #fff;
background-color: #19B5FE;
border-color: #19B5FE;
width: calc(100% + 4rem);
margin-left: -2rem;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
}
.cal-item h2 {
min-height: 54px;
font-family: nunito;
}
.calendar-image {
width: 100%;
height: auto;
/*
min-height: 458px;
*/
}
.year-archive .calendar-image, .fpc .calendar-image {
min-width: 320px;
}
.year-archive .calendar-container , .fpc .calendar-container{
border-radius: 7px;
}
.year-archive h2, .fpc h3 {
font-family: nunito;
}

.month-list {
list-style-type: disclosure-closed;
list-style-position: inside;
}
.mlist {
list-style-type: disclosure-closed;
list-style-position: inside;
}
.twomonth_thumb {
max-width: 220px;
}
.minh-auto {
min-height: auto !important;
}
.relatedc-box {
max-width: 528px;
}
.navbar-toggler:focus {
text-decoration: none;
outline: 0;
box-shadow: initial !important;
}
.watercolor-thumb {
/*
width: 462px;
height: 597px;
*/
width: 231px;
height: 299px;
}
.nunito {
font-family: 'nunito' !important;
}
.poppins {
  font-family: 'Poppins' !important;
}
.watercolor-image {
width: 100%;
aspect-ratio: 645/742;
}
.home-button {
  height: 54px;
  border: none;
}
.home-button:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.25);
}
.home-illustration {
  object-fit: contain;
}
.home-carousel {
  border-radius: 12px;
  max-width: 24rem;
}
.carousel-item {
  margin-bottom: 40px;
}
#app a {
  color: inherit;
}
.fc .fc-scroller-liquid-absolute {
  overflow: hidden !important;
}
.tag {
  display: inline-flex;
  align-items: center;
  padding: 4px 1rem;
  margin-bottom: 5px;
  border: 1px solid #dee2e6;
  border-radius: 20px;
  margin-top: 4px;
  margin-right: 2px;
  font-size: 90%;
}
.halloweenpumpkin {
  padding-bottom: 10px;
  margin-top: -10px;
}
.hover-shadow:hover {
  background-color: #f5f5f5;
  border-radius: 7px;
}
.roundup_thumb {
  max-width: 260px;
}
.form-control {
  border-radius: 3px !important;
}
.contact-form textarea {
  min-height: 180px !important;
}
#recaptcha-element {
  margin-top: 14px;
}
.text-decoration-thick {
  text-decoration-thickness:2px;
  text-decoration: line-through;
}
.text-red {
  color: red !important;
}
.text-green {
  color: green !important;
}
.column-3 {
  column-count: 3 !important;
}
@media (min-width:768px) {
  .column-md-3 {
    column-count: 3 !important;    
  }
  .column-md-2 {
    column-count: 2 !important;
  }
}
.column-2 {
  column-count: 2 !important;
}
@media (max-width:576px) {
  #payment-form {
    min-width: 374px;
    padding: 4px;
    box-shadow: none;
  }
}
/* CUSTOM WIDTHS */
.w-40 {
  width: 40% !important;
}
.w-60 {
  width: 60% !important;
}
.mw-60 {
  min-width: 60% !important;
}
@media (min-width: 576px) {
  .w-sm-50 { width: 50%!important; }
  .w-sm-75 { width: 75%!important; }
}

@media (min-width: 768px) {
  .w-md-50 { width: 50%!important; }
  .w-md-60 { width: 60%!important; }
  .w-md-75 { width: 75%!important; }
  .w-md-auto { width: auto !important; }
}

@media (min-width: 992px) {
  .w-lg-33 { width: 33%!important; }
  .w-lg-40 { width: 40%!important; }
  .w-lg-50 { width: 50%!important; }
  .w-lg-75 { width: 75%!important; }
}
@media (min-width: 1200px) {
  .w-xl-50 { width: 50%!important; }
  .w-xl-75 { width: 75%!important; }
}
@media (min-width: 992px) and (max-width: 1199px) {
.calendar-image, .cal-item img  {
  max-width: 600px;
}
.weekly-related-calendars .bi{
  display: none;
  padding-top: 10px;
}
.weekly--related-calendars li {
  display: flex;
}

.related-calendars.list-group, .weekly-related-calendars.list-group {
  padding: 0 0.15rem 0.75rem 0.2rem !important;
}
.related-calendars h2, .weekly-related-calendars h5 {
  width: calc(100% + 6px);
  margin-left: -3px;
}
} 

@media (min-width: 1150px) and (max-width: 1260px) {
.calendar-image {
  max-width: 700px;
}
.cal-item img  {
  max-width: 333px;
}
}

@media (min-width: 1261px) and (max-width: 1365px) {
.calendar-image  {
  max-width: 800px;
}
.cal-item img  {
  max-width: 340px;
}
}

@media (min-width: 768px) and (max-width: 991px) {
.calendar-image  {
  max-width: 675px;
}
.cal-item img  {
  max-width: 324px;
}
.calendar-container {
  flex-direction: column;
  align-items: center;
}
}

@media (min-width: 576px) and (max-width: 675px) {
.calendar-image, .cal-item img  {
  max-width: 525px;
}
.calendar-container {
  flex-direction: column;
  align-items: center;
}
.calendar .btn-primary.disabled, .month-archive .btn-primary.disabled, .blank-calendar .btn-primary.btn-primary.disabled {
  display: none;
}
}
@media (min-width: 676px) and (max-width: 767px) {
  .calendar-image, .cal-item img  {
    max-width: 625px;
  }
  .calendar-container {
    flex-direction: column;
    align-items: center;
  }
  .calendar .btn-primary.disabled, .month-archive .btn-primary.disabled, .blank-calendar .btn-primary.btn-primary.disabled {
    display: none;
  }
}
@media (max-width: 575px) {

  .calendar-image, .cal-item img {
    max-width: 324px;
  }
  .calendar-container {
    flex-direction: column;
    align-items: center;
  }
  .calendar .btn-primary.disabled, .month-archive .btn-primary.disabled, .blank-calendar .btn-primary.btn-primary.disabled {
    display: none;
  }

  .btn-breadcrumb .btn:first-child {
    font-size: 13px;
    padding: 6px 3px 6px 7px;
    /*
    6 3 0 7*/
  }
  .btn-breadcrumb .btn {
    padding: 3px 12px 6px 12px;
  }
  .fpc.page-title {
    padding-left: 15px !important;
    padding-top: 7px !important;
    padding-bottom: 5px !important;
  }
}

@media (min-width: 576px) {
.title {
  height: 42px;
  line-height: 40px;
  width: 600px;
  text-shadow: -1px 0 0 silver,0 .5px 0 silver,1px 0 0 silver,0 -.5px 0 silver;
  border: 1px solid rgba(173, 173, 173, 0.7);
  border-radius: 10px;
  -moz-box-shadow:    inset 0 0 10px rgba(173, 173, 173, 0.7);
  -webkit-box-shadow: inset 0 0 10px rgba(173, 173, 173, 0.7);
  box-shadow:         inset 0 0 10px rgba(173, 173, 173, 0.7);
}
.year-archive .calendar-container, .fpc .calendar-container {
  background-color: #f5f5f5;
}
.year-archive .calendar-image, .fpc .calendar-image, .blank-calendar .calendar-image {
  min-width: 400px;
  max-width: 880px;
}
.month-list {
  -webkit-columns: 2;
  -moz-columns: 2;
  columns: 2;
}
#breadcrumbs a { 
  padding-top: 4px;
}
.calendar-image {
  min-width: 525px;
}
}
.cal-item {
  background-color: aliceblue;
  padding: 22px;
  border-radius: 5px;
  min-height: 450px;
}
.cal-item-100 {
  min-height: auto;
}
.watercolor .cal-item {
  background-color: transparent;
  min-height: auto;
}
.paa-box {
  padding: .8rem;
  font-size: 18px;
}
.paa-box h3 {
  font-size: 20px;
  font-weight: bold;
}
.calendar-facts {
  border-radius: .35rem;
  border: 1px solid #dee2e6;
}
.calendar-facts div {
  background-color: #dee2e6; 
}
.info-box {
  background-color: #f0f8ff !important;
}
.cal-item {
  width: 100%;
}
.quotes-text {
  background-color: floralwhite;
  max-width: 62%;
  min-height: 80px;
  border-radius: 15px;
}
.font-italic {
  font-style: italic !important;
}
@media (min-width: 768px) {
.cal-item {
  width: 50%;
}
.calendar-image {
  min-width: 675px;
}
#payment-form {
  min-width: 576px;
}
}
@media (max-width: 767px) {
.md-hide {
  display: none !important;
}
}
@media (min-width: 992px) {
.month-list {
  width: 50%;
}
.calendar-image {
  min-width: 496px;
}
.title {
  width: 500px;
}
}
@media (min-width: 1200px) {
  .cal-item {
    width: 32%;
  }
  .month-archive .cal-item {
    width: 48%;
  }
  .calendar-image {
    min-width: 576px;
  }
  .title {
    width: 600px;
  }
}
@media (min-width: 1368px) {
.calendar-image {
  min-width: 744px;
}
}
@media (min-width: 1440px) {
.calendar-image {
  min-width: 768px;
}
}

