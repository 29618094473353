.newsletter-popup-modal .card {
    background-color: #f0f8ff;
}
.newsletter-popup-modal input {
    border-radius: 4px;
    border: solid 1px silver !important;
}

.newsletter-popup-modal .sendbutton {
    font-family: roboto;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    border-radius: 4px;
    border: none !important;
}
.newsletter-popup-modal .sendbutton:hover {
    border: 1px solid;
    box-shadow: inset 0 0 20px hsla(0,0%,100%,.35),0 0 20px hsla(0,0%,100%,.2);
    outline-color: hsla(0,0%,100%,0);
    outline-offset: 15px;
}