.fpc-card {
    border-radius: 5px;
    background-color: #fff;
    display: flex;
}
.fpc-card-thumb {
    width: 255px;
    padding: 5px 5px 5px 10px;
    margin: 10px 18px 10px 5px;
    min-height: 220px;
    max-height: 250px;
}
.monthly-list-margin {
    margin-left: 280px;
}
@media (max-width:767px) {
    .fpc-card-thumb {
        margin: auto;
    }
    .fpc-card {
        flex-direction: column;
    }
    .monthly-list-margin {
        margin-left: 0px;
    }
}
.fpc-card-thumb img {
    width: 100%;
}