// Body
$body-bg: #f8fafc;

// Typography
//$font-family-sans-serif: 'Nunito', sans-serif;
$font-family-sans-serif: 'Roboto', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$primary: #19B5FE;
$secondary: #d7f9ef;
$gray: #6c757d;
$gray-dark: #3f4254;
//$dark: #181c32;
$dark: #333;
$light: #adb1c7;
$info: #cceef2;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1366px
);
