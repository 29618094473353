// Fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700;900&display=swap'); 
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

// Variables
@import 'variables';

@import '../css/styles.css';
@import '../css/font-sizes.css';
@import '../css/auth.css';
@import '../css/fpc.css';
@import '../css/tables.css';
@import '../css/blog.css';

// Blade Components
@import '../css/components/howto.css';
@import '../css/components/home-printable-calendars.css';
@import '../css/components/sidebar.css';
@import '../css/icons.css';
@import '../css/colors.css';
@import '../css/components/share-button.css';
@import '../css/components/newsletter-popup.css';
@import '../css/components/footer.css';
@import '../css/components/print-dialog.css';
@import '../css/components/calendar-edit-block.css';
@import '../css/components/ratings-widget.css';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.6.1/font/bootstrap-icons.css");

@import '~animate.css/animate.css';

a {
    text-decoration: none;
}
body {
    background-color: #fff;
}
.list-group {
    font-family: Nunito;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 0rem 2rem 0.75rem 2rem;
    list-style-type: none;
    /*
    box-shadow: inset 0 0 10px rgba(173, 173, 173, 0.7);
    */
    box-shadow: 0 0 10px rgba(173, 173, 173, 0.7);
}
.list-group-item {
    border: none;
}
.list-group-item.active {
    z-index: 2;
    text-decoration: underline;
    font-weight: bold;
    background-color: #fff;
    color: inherit;
    padding-left: 5px;
    text-underline-offset: 5px;
  }
.related-calendars .bi {
    display: none;
    padding-top: 10px;
}
.active.bi {
    display: block;
}
.related-calendars li {
    display: flex;
}