.post-content h1, .post-content h2, .post-content h3, .post-content h4 {
    font-family: 'Nunito';
    font-weight: bold !important;
    margin-top: 0.5rem !important;
}

.post-content thead {
    font-weight: bold;
}

.post-content p {
    margin-bottom: 0.75rem;
}
.post-sidebar {
    min-width: 360px;
}
.post-sidebar .list-group-item {
    padding: 0.25rem 0;
}
.post-sidebar .related-calendars .bi {
    padding-top: 5px;
}
.post-content img {
    margin: 0.25rem auto 0.25rem auto;
    max-width: 100%;
}
@media (min-width:768px) {
    .post-content img {
        max-width: 66%;
    }
}