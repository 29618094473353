.cal-edit-preview {
    width: 94%;
}
.cal-edit-controls {
    width: 94%;
}

.cal-edit-controls .btn, .controls.btn {
    background-color: #EEEEEE;
    border-radius: 0 !important;
    border: none !important;
    color: gray;
    font-size: 16px;
}
.cal-edit-controls .btn:hover, .controls.btn:hover {
    background-color: silver;
}
.cal-edit-controls .btn.active, .controls.btn.active {
    background-color: #19b5fe;
    color: white;
    font-weight: bold;
}
.color-pick-current {
    width:68px;
    height: 68px;
    border:1px solid #ddd;
    margin:4px;
    border-radius:4px;
}
.color-pick-color {
    width:30px;
    height: 30px;
    border:1px solid #ddd;
    margin:4px;
    border-radius:4px;
}
.color-pick-color:hover {
    cursor: pointer;
}
@media screen and (min-width: 1200px) {
    .calendar-edit-block-printbutton {
        width: 60%;
    }
}

@media screen and (min-width: 1366px) {
    .cal-edit-preview {
        width: 60%;
    }
    .cal-edit-controls {
        width: 40%;
    }
    .cal-edit-controls .card {
        min-height: 382px;
    }
}
  