.fw-600 {font-weight: 600 !important;}
.fz-4 { font-size: 4px !important;}
.fz-6 { font-size: 6px !important;}
.fz-8 { font-size: 8px !important; }
.fz-10 { font-size: 10px !important; }
.fz-12 { font-size: 12px !important; }
.fz-13 { font-size: 13px !important; }
.fz-14 { font-size: 14px !important; }
.fz-15 { font-size: 15px !important; }
.fz-16 { font-size: 16px !important; }
.fz-17 { font-size: 17px !important; }
.fz-18 { font-size: 18px !important; }
.fz-19 { font-size: 19px !important; }
.fz-20 { font-size: 20px !important; }
.fz-21 { font-size: 21px !important; }
.fz-22 { font-size: 22px !important; }
.fz-24 { font-size: 24px !important; }
.fz-26 { font-size: 26px !important; }
.fz-28 { font-size: 28px !important; }
.fz-30 { font-size: 30px !important; }
.fz-32 { font-size: 32px !important; }
.fz-34 { font-size: 34px !important; }
.fz-36 { font-size: 36px !important; }
.fz-38 { font-size: 38px !important; }
.fz-40 { font-size: 40px !important; }
.fz-42 { font-size: 42px !important; }
.fz-44 { font-size: 44px !important; }
.fz-46 { font-size: 46px !important; }
.fz-48 { font-size: 48px !important; }
.fz-64 { font-size: 64px !important; }
.fz-50 { font-size: 50px !important; }
.fz-52 { font-size: 52px !important; }
.fz-54 { font-size: 54px !important; }
.fz-56 { font-size: 56px !important; }
.fz-58 { font-size: 58px !important; }
.fz-60 { font-size: 60px !important; }
.fz-62 { font-size: 62px !important; }
.fz-66 { font-size: 66px !important; }
.fz-68 { font-size: 68px !important; }
.fz-70 { font-size: 70px !important; }
.fz-72 { font-size: 72px !important; }
.fz-112 { font-size: 112px !important; }

@media (min-width: 576px) {
  .fz-sm-8 { font-size: 8px !important; }
  .fz-sm-10 { font-size: 10px !important; }
  .fz-sm-12 { font-size: 12px !important; }
  .fz-sm-13 { font-size: 13px !important; }
  .fz-sm-14 { font-size: 14px !important; }
  .fz-sm-15 { font-size: 15px !important; }
  .fz-sm-16 { font-size: 16px !important; }
  .fz-sm-17 { font-size: 17px !important; }
  .fz-sm-18 { font-size: 18px !important; }
  .fz-sm-20 { font-size: 20px !important; }
  .fz-sm-22 { font-size: 22px !important; }
  .fz-sm-24 { font-size: 24px !important; }
  .fz-sm-26 { font-size: 26px !important; }
  .fz-sm-28 { font-size: 28px !important; }
  .fz-sm-30 { font-size: 30px !important; }
  .fz-sm-32 { font-size: 32px !important; }
  .fz-sm-34 { font-size: 34px !important; }
  .fz-sm-36 { font-size: 36px !important; }
  .fz-sm-38 { font-size: 38px !important; }
  .fz-sm-40 { font-size: 40px !important; }
  .fz-sm-42 { font-size: 42px !important; }
  .fz-sm-44 { font-size: 44px !important; }
  .fz-sm-45 { font-size: 45px !important; }
  .fz-sm-46 { font-size: 46px !important; }
  .fz-sm-48 { font-size: 48px !important; }
  .fz-sm-64 { font-size: 64px !important; }
  .fz-sm-50 { font-size: 50px !important; }
  .fz-sm-52 { font-size: 52px !important; }
  .fz-sm-54 { font-size: 54px !important; }
  .fz-sm-56 { font-size: 56px !important; }
  .fz-sm-58 { font-size: 58px !important; }
  .fz-sm-60 { font-size: 60px !important; }
  .fz-sm-62 { font-size: 62px !important; }
  .fz-sm-66 { font-size: 66px !important; }
  .fz-sm-68 { font-size: 68px !important; }
  .fz-sm-70 { font-size: 70px !important; }
  .fz-sm-72 { font-size: 72px !important; }
  .fz-sm-112 { font-size: 112px !important; }
}

@media (min-width: 768px) {
  .fz-md-8 { font-size: 8px !important; }
  .fz-md-10 { font-size: 10px !important; }
  .fz-md-12 { font-size: 12px !important; }
  .fz-md-13 { font-size: 13px !important; }
  .fz-md-14 { font-size: 14px !important; }
  .fz-md-15 { font-size: 15px !important; }
  .fz-md-16 { font-size: 16px !important; }
  .fz-md-17 { font-size: 17px !important; }
  .fz-md-18 { font-size: 18px !important; }
  .fz-md-20 { font-size: 20px !important; }
  .fz-md-22 { font-size: 22px !important; }
  .fz-md-24 { font-size: 24px !important; }
  .fz-md-26 { font-size: 26px !important; }
  .fz-md-28 { font-size: 28px !important; }
  .fz-md-30 { font-size: 30px !important; }
  .fz-md-32 { font-size: 32px !important; }
  .fz-md-34 { font-size: 34px !important; }
  .fz-md-36 { font-size: 36px !important; }
  .fz-md-38 { font-size: 38px !important; }
  .fz-md-40 { font-size: 40px !important; }
  .fz-md-42 { font-size: 42px !important; }
  .fz-md-44 { font-size: 44px !important; }
  .fz-md-46 { font-size: 46px !important; }
  .fz-md-48 { font-size: 48px !important; }
  .fz-md-64 { font-size: 64px !important; }
  .fz-md-50 { font-size: 50px !important; }
  .fz-md-52 { font-size: 52px !important; }
  .fz-md-54 { font-size: 54px !important; }
  .fz-md-56 { font-size: 56px !important; }
  .fz-md-58 { font-size: 58px !important; }
  .fz-md-60 { font-size: 60px !important; }
  .fz-md-62 { font-size: 62px !important; }
  .fz-md-66 { font-size: 66px !important; }
  .fz-md-68 { font-size: 68px !important; }
  .fz-md-70 { font-size: 70px !important; }
  .fz-md-72 { font-size: 72px !important; }
  .fz-md-112 { font-size: 112px !important; }
}

@media (min-width: 992px) {
  .fz-lg-8 { font-size: 8px !important; }
  .fz-lg-10 { font-size: 10px !important; }
  .fz-lg-12 { font-size: 12px !important; }
  .fz-lg-13 { font-size: 13px !important; }
  .fz-lg-14 { font-size: 14px !important; }
  .fz-lg-15 { font-size: 15px !important; }
  .fz-lg-16 { font-size: 16px !important; }
  .fz-lg-17 { font-size: 17px !important; }
  .fz-lg-18 { font-size: 18px !important; }
  .fz-lg-20 { font-size: 20px !important; }
  .fz-lg-22 { font-size: 22px !important; }
  .fz-lg-24 { font-size: 24px !important; }
  .fz-lg-26 { font-size: 26px !important; }
  .fz-lg-28 { font-size: 28px !important; }
  .fz-lg-30 { font-size: 30px !important; }
  .fz-lg-32 { font-size: 32px !important; }
  .fz-lg-34 { font-size: 34px !important; }
  .fz-lg-36 { font-size: 36px !important; }
  .fz-lg-38 { font-size: 38px !important; }
  .fz-lg-40 { font-size: 40px !important; }
  .fz-lg-42 { font-size: 42px !important; }
  .fz-lg-44 { font-size: 44px !important; }
  .fz-lg-46 { font-size: 46px !important; }
  .fz-lg-48 { font-size: 48px !important; }
  .fz-lg-64 { font-size: 64px !important; }
  .fz-lg-50 { font-size: 50px !important; }
  .fz-lg-52 { font-size: 52px !important; }
  .fz-lg-54 { font-size: 54px !important; }
  .fz-lg-56 { font-size: 56px !important; }
  .fz-lg-58 { font-size: 58px !important; }
  .fz-lg-60 { font-size: 60px !important; }
  .fz-lg-62 { font-size: 62px !important; }
  .fz-lg-66 { font-size: 66px !important; }
  .fz-lg-68 { font-size: 68px !important; }
  .fz-lg-70 { font-size: 70px !important; }
  .fz-lg-72 { font-size: 72px !important; }
  .fz-lg-112 { font-size: 112px !important; }
}

@media (min-width: 1200px) {
  .fz-xl-8 { font-size: 8px !important; }
  .fz-xl-10 { font-size: 10px !important; }
  .fz-xl-12 { font-size: 12px !important; }
  .fz-xl-13 { font-size: 13px !important; }
  .fz-xl-14 { font-size: 14px !important; }
  .fz-xl-15 { font-size: 15px !important; }
  .fz-xl-16 { font-size: 16px !important; }
  .fz-xl-17 { font-size: 17px !important; }
  .fz-xl-18 { font-size: 18px !important; }
  .fz-xl-20 { font-size: 20px !important; }
  .fz-xl-22 { font-size: 22px !important; }
  .fz-xl-24 { font-size: 24px !important; }
  .fz-xl-26 { font-size: 26px !important; }
  .fz-xl-28 { font-size: 28px !important; }
  .fz-xl-30 { font-size: 30px !important; }
  .fz-xl-32 { font-size: 32px !important; }
  .fz-xl-34 { font-size: 34px !important; }
  .fz-xl-36 { font-size: 36px !important; }
  .fz-xl-38 { font-size: 38px !important; }
  .fz-xl-40 { font-size: 40px !important; }
  .fz-xl-42 { font-size: 42px !important; }
  .fz-xl-44 { font-size: 44px !important; }
  .fz-xl-46 { font-size: 46px !important; }
  .fz-xl-48 { font-size: 48px !important; }
  .fz-xl-50 { font-size: 50px !important; }
  .fz-xl-52 { font-size: 52px !important; }
  .fz-xl-54 { font-size: 54px !important; }
  .fz-xl-56 { font-size: 56px !important; }
  .fz-xl-58 { font-size: 58px !important; }
  .fz-xl-60 { font-size: 60px !important; }
  .fz-xl-62 { font-size: 62px !important; }
  .fz-xl-64 { font-size: 64px !important; }
  .fz-xl-66 { font-size: 66px !important; }
  .fz-xl-68 { font-size: 68px !important; }
  .fz-xl-70 { font-size: 70px !important; }
  .fz-xl-72 { font-size: 72px !important; }
  .fz-xl-112 { font-size: 112px !important; }
}
@media (min-width: 1400px) {
  .fz-xxl-20 { font-size: 20px !important; }
  .fz-xxl-34 { font-size: 34px !important; }
}
@media (min-width: 1500px) {
  .fz-xxl-32 { font-size: 32px !important; }
}
.lh-4 { line-height: 4px !important;}
.lh-6 { line-height: 6px !important;}
.lh-8 { line-height: 8px !important; }
.lh-10 { line-height: 10px !important; }
.lh-12 { line-height: 12px !important; }
.lh-13 { line-height: 13px !important; }
.lh-14 { line-height: 14px !important; }
.lh-15 { line-height: 15px !important; }
.lh-16 { line-height: 16px !important; }
.lh-17 { line-height: 17px !important; }
.lh-18 { line-height: 18px !important; }
.lh-20 { line-height: 20px !important; }
.lh-21 { line-height: 21px !important; }
.lh-22 { line-height: 22px !important; }
.lh-24 { line-height: 24px !important; }
.lh-26 { line-height: 26px !important; }
.lh-28 { line-height: 28px !important; }
.lh-30 { line-height: 30px !important; }
.lh-32 { line-height: 32px !important; }
.lh-34 { line-height: 34px !important; }
.lh-35 { line-height: 35px !important; }
.lh-36 { line-height: 36px !important; }
.lh-38 { line-height: 38px !important; }
.lh-40 { line-height: 40px !important; }
.lh-42 { line-height: 42px !important; }
.lh-44 { line-height: 44px !important; }
.lh-46 { line-height: 46px !important; }
.lh-48 { line-height: 48px !important; }
.lh-64 { line-height: 64px !important; }
.lh-50 { line-height: 50px !important; }
.lh-52 { line-height: 52px !important; }
.lh-54 { line-height: 54px !important; }
.lh-56 { line-height: 56px !important; }
.lh-58 { line-height: 58px !important; }
.lh-60 { line-height: 60px !important; }
.lh-62 { line-height: 62px !important; }
.lh-66 { line-height: 66px !important; }
.lh-68 { line-height: 68px !important; }
.lh-70 { line-height: 70px !important; }
.lh-72 { line-height: 72px !important; }
.lh-112 { line-height: 112px !important; }

@media (min-width: 576px) {
  .lh-sm-8 { line-height: 8px !important; }
  .lh-sm-10 { line-height: 10px !important; }
  .lh-sm-12 { line-height: 12px !important; }
  .lh-sm-13 { line-height: 13px !important; }
  .lh-sm-14 { line-height: 14px !important; }
  .lh-sm-15 { line-height: 15px !important; }
  .lh-sm-16 { line-height: 16px !important; }
  .lh-sm-17 { line-height: 17px !important; }
  .lh-sm-18 { line-height: 18px !important; }
  .lh-sm-20 { line-height: 20px !important; }
  .lh-sm-22 { line-height: 22px !important; }
  .lh-sm-24 { line-height: 24px !important; }
  .lh-sm-26 { line-height: 26px !important; }
  .lh-sm-28 { line-height: 28px !important; }
  .lh-sm-30 { line-height: 30px !important; }
  .lh-sm-32 { line-height: 32px !important; }
  .lh-sm-34 { line-height: 34px !important; }
  .lh-sm-36 { line-height: 36px !important; }
  .lh-sm-38 { line-height: 38px !important; }
  .lh-sm-40 { line-height: 40px !important; }
  .lh-sm-42 { line-height: 42px !important; }
  .lh-sm-44 { line-height: 44px !important; }
  .lh-sm-46 { line-height: 46px !important; }
  .lh-sm-48 { line-height: 48px !important; }
  .lh-sm-64 { line-height: 64px !important; }
  .lh-sm-50 { line-height: 50px !important; }
  .lh-sm-52 { line-height: 52px !important; }
  .lh-sm-54 { line-height: 54px !important; }
  .lh-sm-56 { line-height: 56px !important; }
  .lh-sm-58 { line-height: 58px !important; }
  .lh-sm-60 { line-height: 60px !important; }
  .lh-sm-62 { line-height: 62px !important; }
  .lh-sm-66 { line-height: 66px !important; }
  .lh-sm-68 { line-height: 68px !important; }
  .lh-sm-70 { line-height: 70px !important; }
  .lh-sm-72 { line-height: 72px !important; }
  .lh-sm-112 { line-height: 112px !important; }
}

@media (min-width: 768px) {
  .lh-md-8 { line-height: 8px !important; }
  .lh-md-10 { line-height: 10px !important; }
  .lh-md-12 { line-height: 12px !important; }
  .lh-md-13 { line-height: 13px !important; }
  .lh-md-14 { line-height: 14px !important; }
  .lh-md-15 { line-height: 15px !important; }
  .lh-md-16 { line-height: 16px !important; }
  .lh-md-17 { line-height: 17px !important; }
  .lh-md-18 { line-height: 18px !important; }
  .lh-md-20 { line-height: 20px !important; }
  .lh-md-22 { line-height: 22px !important; }
  .lh-md-24 { line-height: 24px !important; }
  .lh-md-26 { line-height: 26px !important; }
  .lh-md-28 { line-height: 28px !important; }
  .lh-md-30 { line-height: 30px !important; }
  .lh-md-32 { line-height: 32px !important; }
  .lh-md-34 { line-height: 34px !important; }
  .lh-md-36 { line-height: 36px !important; }
  .lh-md-38 { line-height: 38px !important; }
  .lh-md-40 { line-height: 40px !important; }
  .lh-md-42 { line-height: 42px !important; }
  .lh-md-44 { line-height: 44px !important; }
  .lh-md-46 { line-height: 46px !important; }
  .lh-md-48 { line-height: 48px !important; }
  .lh-md-64 { line-height: 64px !important; }
  .lh-md-50 { line-height: 50px !important; }
  .lh-md-52 { line-height: 52px !important; }
  .lh-md-54 { line-height: 54px !important; }
  .lh-md-56 { line-height: 56px !important; }
  .lh-md-58 { line-height: 58px !important; }
  .lh-md-60 { line-height: 60px !important; }
  .lh-md-62 { line-height: 62px !important; }
  .lh-md-66 { line-height: 66px !important; }
  .lh-md-68 { line-height: 68px !important; }
  .lh-md-70 { line-height: 70px !important; }
  .lh-md-72 { line-height: 72px !important; }
  .lh-md-112 { line-height: 112px !important; }
}

@media (min-width: 992px) {
  .lh-lg-8 { line-height: 8px !important; }
  .lh-lg-10 { line-height: 10px !important; }
  .lh-lg-12 { line-height: 12px !important; }
  .lh-lg-13 { line-height: 13px !important; }
  .lh-lg-14 { line-height: 14px !important; }
  .lh-lg-15 { line-height: 15px !important; }
  .lh-lg-16 { line-height: 16px !important; }
  .lh-lg-17 { line-height: 17px !important; }
  .lh-lg-18 { line-height: 18px !important; }
  .lh-lg-20 { line-height: 20px !important; }
  .lh-lg-22 { line-height: 22px !important; }
  .lh-lg-24 { line-height: 24px !important; }
  .lh-lg-26 { line-height: 26px !important; }
  .lh-lg-28 { line-height: 28px !important; }
  .lh-lg-30 { line-height: 30px !important; }
  .lh-lg-32 { line-height: 32px !important; }
  .lh-lg-34 { line-height: 34px !important; }
  .lh-lg-36 { line-height: 36px !important; }
  .lh-lg-38 { line-height: 38px !important; }
  .lh-lg-40 { line-height: 40px !important; }
  .lh-lg-42 { line-height: 42px !important; }
  .lh-lg-44 { line-height: 44px !important; }
  .lh-lg-46 { line-height: 46px !important; }
  .lh-lg-48 { line-height: 48px !important; }
  .lh-lg-64 { line-height: 64px !important; }
  .lh-lg-50 { line-height: 50px !important; }
  .lh-lg-52 { line-height: 52px !important; }
  .lh-lg-54 { line-height: 54px !important; }
  .lh-lg-56 { line-height: 56px !important; }
  .lh-lg-58 { line-height: 58px !important; }
  .lh-lg-60 { line-height: 60px !important; }
  .lh-lg-62 { line-height: 62px !important; }
  .lh-lg-66 { line-height: 66px !important; }
  .lh-lg-68 { line-height: 68px !important; }
  .lh-lg-70 { line-height: 70px !important; }
  .lh-lg-72 { line-height: 72px !important; }
  .lh-lg-112 { line-height: 112px !important; }
}

@media (min-width: 1200px) {
  .lh-xl-8 { line-height: 8px !important; }
  .lh-xl-10 { line-height: 10px !important; }
  .lh-xl-12 { line-height: 12px !important; }
  .lh-xl-13 { line-height: 13px !important; }
  .lh-xl-14 { line-height: 14px !important; }
  .lh-xl-15 { line-height: 15px !important; }
  .lh-xl-16 { line-height: 16px !important; }
  .lh-xl-17 { line-height: 17px !important; }
  .lh-xl-18 { line-height: 18px !important; }
  .lh-xl-20 { line-height: 20px !important; }
  .lh-xl-22 { line-height: 22px !important; }
  .lh-xl-24 { line-height: 24px !important; }
  .lh-xl-26 { line-height: 26px !important; }
  .lh-xl-28 { line-height: 28px !important; }
  .lh-xl-30 { line-height: 30px !important; }
  .lh-xl-32 { line-height: 32px !important; }
  .lh-xl-34 { line-height: 34px !important; }
  .lh-xl-36 { line-height: 36px !important; }
  .lh-xl-38 { line-height: 38px !important; }
  .lh-xl-40 { line-height: 40px !important; }
  .lh-xl-42 { line-height: 42px !important; }
  .lh-xl-44 { line-height: 44px !important; }
  .lh-xl-46 { line-height: 46px !important; }
  .lh-xl-48 { line-height: 48px !important; }
  .lh-xl-50 { line-height: 50px !important; }
  .lh-xl-52 { line-height: 52px !important; }
  .lh-xl-54 { line-height: 54px !important; }
  .lh-xl-56 { line-height: 56px !important; }
  .lh-xl-58 { line-height: 58px !important; }
  .lh-xl-60 { line-height: 60px !important; }
  .lh-xl-62 { line-height: 62px !important; }
  .lh-xl-64 { line-height: 64px !important; }
  .lh-xl-66 { line-height: 66px !important; }
  .lh-xl-68 { line-height: 68px !important; }
  .lh-xl-70 { line-height: 70px !important; }
  .lh-xl-72 { line-height: 72px !important; }
  .lh-xl-112 { line-height: 112px !important; }
}
@media (min-width: 1500px) {
  .lh-xxl-32 { line-height: 32px !important; }
}