.bg-aliceblue {
    background-color: aliceblue !important;
}
.bg-transparent {
    background-color: transparent !important;
}
.text-grey {
    color: #64748b;
}
.rotate-45 {
    transform: rotate(45deg);
}
.rotate-90 {
    transform: rotate(90deg);
}