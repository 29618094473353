.share-button-modal, .newsletter-popup-modal {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.68);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
}
.share-button-modal .card, .newsletter-popup-modal .card {
    width: 52rem;
}
.share-button {
    position: fixed;
    bottom:1.5rem;
    right: 2rem;
    border: none;
    border-radius: 50%;
    padding: 0.7rem 1.2rem;
    background-color: white;
}
.social-button {
    min-width: 180px;
    height: 42px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.social-button:hover {
    border: 1px solid;
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.35), 0 0 20px rgba(255, 255, 255, 0.2);
    outline-color: rgba(255, 255, 255, 0);
    outline-offset: 15px;
}
.social-button.fb {
    background-color: #4460A0;
}
.social-button.pinterest {
    background-color: #BD081C;
}
.social-button.tw {
    background-color: #47ACDF;
}
.social-button.mail {
    background-color: gray;
}
.social-button.fb svg {
    width: 26px;
}
.social-button svg  {
    width: 32px;
}

body.modal-open {
    height: 100vh;
    overflow-y: hidden;
    padding-right: 15px; 
}
.link-code {
    border: 1px solid;
}