aside {
    min-width: 360px;
    border-left: 1px solid lightgray;
}
aside .border-accordion-item:hover {
    background-color: #e6effc;
}
.border-accordion-item.active:hover {
    background-color: #f6f8fd;
}
aside a:hover .bi-chevron-down {
    display: inline-block;
    border-radius: 20px;
    padding: 0.25em 0.6em;
    background-color: white;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
}
.bg-accordion {
    background-color: #f6f8fd !important;
}
.border-accordion-item .item {
    border-bottom: 1px solid #c5dcfa;
}
.item-title .bi {
    -webkit-text-stroke: 1px;
    padding: 0.25em 0.6em;
    color: #0a3977;
}
.item .active {
    text-underline-offset: 4px;
}